import React from "react"
import Footer from "../../components/Footer/Footer";

const styles = require("../../styles/pages/General.module.scss");

export default function Providers() {
    return (
        <div>
            <section style={{paddingBottom:"24px"}}>
                <h1>Our Service Providers</h1>
                <p>We work with third-party service providers (“Subprocessors”) to help run our business. These providers sometimes handle processing activities that involve access to customer data. We have established GDPR-compliant data processing agreements with each Subprocessor.</p>

                <p>The following list provides details about each Subprocessor we do business with. All Subprocessors are located in the United States.</p>

                <div className={styles.Providers}>
                    <p>
                        <a href="https://aws.amazon.com/compliance/gdpr-center" target="_blank">Amazon Web Services.&nbsp;</a>
                        Cloud services provider.
                    </p>
                    <p>
                        <a href="https://cloud.google.com/security/gdpr/resource-center" target="_blank">Google Cloud Platform.&nbsp;</a>
                        Cloud services provider.
                    </p>
                    <p>
                        <a href="https://stripe.com/en-gb-us/guides/general-data-protection-regulation" target="_blank">Stripe.&nbsp;</a>
                        Payment processing services.
                    </p>
                    <p>
                        <a href="https://postmarkapp.com/eu-privacy" target="_blank">Postmark.&nbsp;</a>
                        Transactional email service.
                    </p>
                    <p>
                        <a href="https://www.zendesk.com/company/privacy-and-data-protection/" target="_blank">Zendesk.&nbsp;</a>
                        Help desk service.
                    </p>
                    <p>
                        <a href="https://support.loom.com/hc/en-us/articles/360002228758-GDPR-compliance" target="_blank">Loom.&nbsp;</a>
                        Screen recording software used to provide support services.
                    </p>
                    <p>
                        <a href="https://zoom.us/gdpr" target="_blank">Zoom.</a>
                        Video conferencing software.
                    </p>
                    <p>
                        <a href="https://slack.com/trust/compliance/gdpr" target="_blank">Slack.&nbsp;</a>
                        Team communication software.
                    </p>
                    <p>
                        <a href="https://www.twilio.com/gdpr" target="_blank">Twilio.&nbsp;</a>
                        Messaging software.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    )
}